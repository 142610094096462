/*
 To reduce Cumulative Layout Shift (CLS),
 we are adding margin-right values to the main page swiper elements
 and making the category swiper height responsive,
 adjusting it according to the screen size.
 */

.home-deals,
.foreign-deal-list-swiper,
.pp-packages-swiper {
	.swiper-slide {
		margin-right: 24px;
	}
}

.home-accommodations-swiper {
	.swiper-slide {
		margin-right: 15px;
	}
}

@media screen and (max-width: 575px) {
	.popular-destinations-swiper {
		.swiper-slide {
			margin-right: 15px;
		}
	}
}

.home-categories-swiper {
	.swiper-slide {
		margin-right: 25px;
	}
}

.categories-section {
	height: 220px;
	overflow: hidden;
	@media screen and (min-width: 575px) and (max-width: 1200px) {
		height: 320px;
	}

	@media screen and (min-width: 1201px) and (max-width: 1540px) {
		height: 300px;
	}

	@media screen and (min-width: 1541px) {
		height: 250px;
	}
}
